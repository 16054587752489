import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './child-segment-groups.styles';
import { getProductGroupsAttrName } from './child-segment-groups.utils';
import { ABTestContext } from '../../../context/ab-test-context';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { selectChildSegmentGroups } from '../../../redux/segments/segments-selectors';
import { ChildSegmentGroup } from '../child-segment-group/child-segment-group';

export const ChildSegmentGroups: FC = () => {
    const childSegmentsGroups = useSelector(selectChildSegmentGroups);
    const { mts_bank_group: abTest } = useContext(ABTestContext);
    const { isMyMtsApp } = useIsApp();
    const filterChildSegmentsGroups = isMyMtsApp || abTest ? childSegmentsGroups?.filter(item => item.code !== 'mts_bank') : childSegmentsGroups;

    return filterChildSegmentsGroups?.length ? (
        <Styled.ChildSegmentGroupsList abTest={abTest} data-test={getProductGroupsAttrName()}>
            {/*splice для того, чтобы групп было не больше трех, как по макетам*/}
            {filterChildSegmentsGroups
                .slice(0)
                .splice(0, 3)
                .map((group, index) => (
                    <ChildSegmentGroup key={group.name} group={group} index={index} />
                ))}
        </Styled.ChildSegmentGroupsList>
    ) : null;
};
