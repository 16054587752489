import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';

export const ChildSegmentGroupsList = styled.div<{ abTest: boolean }>`
    display: grid;
    grid-template-columns: repeat(${({ abTest }) => abTest ? 2 : 3}, minmax(200px, 1fr));
    gap: ${rem(32)};
    margin-bottom: ${rem(40)};

    ${media(tablet, mobile)`grid-template-columns: 1fr;`};
    ${tablet`gap: ${rem(24)}`};
    ${mobile`gap: ${rem(20)}`};
`;
