import { useEffect, useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const useGtm = () => {
    const initialize = useCallback(() => {
        const tagManagerArgs = { gtmId: process.env.API_KEYS.GTM };
        TagManager.initialize(tagManagerArgs);
    }, []);

    useEffect(() => {
        initialize();

        if ((window as Window).dataLayer) {
            (window as Window).dataLayer.push({
                originalLocation:
                    document.location.protocol +
                    '//' +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search,
            });
        }
    }, []);
};
