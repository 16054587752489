import React, { FC, useCallback } from 'react';
import { hardcodedProducts } from './child-segment-group.constants';
import * as Styled from './child-segment-group.styles';
import { getGroupListAttrName, getGroupTitleAttrName, getProductGroupAttrName } from './child-segment-group.utils';
import { ShortProduct } from '../../../models/product';
import { ChildSegment } from '../../../models/segment';
import { AppLink } from '../../app-link';
import ChildSegmentLink from '../child-segment-link/child-segment-link';

interface ServicesGroupProps {
    group: ChildSegment;
    index: number;
}

export const ChildSegmentGroup: FC<ServicesGroupProps> = ({ group, index }) => {
    const filterProducts = useCallback((item: ShortProduct) => {
        return hardcodedProducts.filter((product) => product.code === item.code);
    }, []);

    return (
        <Styled.Wrapper data-test={getProductGroupAttrName(index)}>
            <AppLink href={'/child_segment/[child_segment]'} as={`/child_segment/${group.code}`} key={group.id}>
                <Styled.GroupTitle data-test={getGroupTitleAttrName(index)}>{group?.name}</Styled.GroupTitle>
            </AppLink>
            <Styled.GroupList data-test={getGroupListAttrName(index)}>
                {group.products.map((product, index) => {
                    const filtered = filterProducts(product);
                    return (
                        <ChildSegmentLink
                            externalLink={filtered[0]?.href}
                            product={product}
                            key={product.id}
                            groupIndex={index}
                            isGroupVariant
                        />
                    );
                })}
            </Styled.GroupList>
        </Styled.Wrapper>
    );
};
