import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './child-segments.styles';
import { getPartnersColumns, hideProducts, splitChildSegments } from './child-segments.utils';
import { useMatchMedia } from '../../../custom-hooks/use-match-media';
import { MediaWidthEnum } from '../../../models/enums/media-width.enum';
import { SegmentsEnum } from '../../../models/enums/segments.enum';
import { selectChildSegments, selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import { ChildSegmentGroups } from '../child-segment-groups/child-segment-groups';
import { SegmentProducts } from '../segment-products/segment-products';

export const ChildSegments = () => {
    const childSegments = useSelector(selectChildSegments);
    const currentSegmentCode = useSelector(selectCurrentSegmentCode);
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);
    const [isTablet] = useMatchMedia(MediaWidthEnum.tablet);
    const isPartners = currentSegmentCode === SegmentsEnum.partners;
    const withoutHiddenProducts = hideProducts(childSegments);

    let columnsCount = 3;
    if (isMobile) {
        columnsCount = 1;
    } else if (isTablet) {
        columnsCount = 2;
    }

    const columns = useMemo(() => {
        if (isPartners) {
            return getPartnersColumns(columnsCount, withoutHiddenProducts);
        }
        return splitChildSegments(columnsCount, withoutHiddenProducts);
    }, [childSegments, columnsCount]);

    return (
        <div>
            <ChildSegmentGroups />
            <Styled.ChildSegmentsBlock>
                {columns.map((column, index) => (
                    <div key={index}>
                        {column.map(childSegment => (
                            <SegmentProducts childSegment={childSegment} key={childSegment.id} />
                        ))}
                    </div>
                ))}
            </Styled.ChildSegmentsBlock>
        </div>
    );
};
