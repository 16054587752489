import React, { FC } from 'react';
import { Url } from 'url';
import { LinkProps } from 'next/dist/client/link';
import Link from 'next/link';
import { useWithUtm } from '../custom-hooks/use-with-utm';

interface AppLinkProps extends LinkProps {
    href: string | Partial<Url>;
    as?: string | Partial<Url>;
    children: React.ReactNode;
    disabled?: boolean;
}

export const AppLink: FC<AppLinkProps> = ({ href, as, children, disabled, ...props }) => {
    const newHref = useWithUtm(href);
    const newAs = useWithUtm(as);

    return disabled ? <>{children}</> :
        <Link href={newHref || ''} as={newAs} {...props}>{children}</Link>;
};
