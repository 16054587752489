// Используется для покрытия приложения автотестами

export const pageNames = {
    main: 'main-page',
    productGroups: 'product-groups-page',
    product: 'product-page',
    section: 'section-page',
    article: 'article-page',
    search: 'search-page',
    // TODO: добавить страницу ошибок после имлпементации
};

export const elementNames = {
    title: 'title',
    subTitle: 'sub-title',
};
