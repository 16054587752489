export const hiddenProducts = [
    'gogym',
    'mts_dengi_lite',
    'mts_dengi',
    'beri_zaryad',
    'tododo',
    'mts-tsmr',
    'loncher',
    'membrana_kids',
    'membrana',
    'zvonki_cherez_yandeks_stantsiyu',
    'zvonki_v_moi_mts',
];
