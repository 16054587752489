import { localElementNames } from './child-segment-group.constants';
import { pageNames } from '../../../constants/data-attribute-names';
import { getDataTestAttrName } from '../../../helpers/get-data-test-attr-name';

export const getProductGroupAttrName = (index: number): string => {
    return getDataTestAttrName(pageNames.main, localElementNames.productGroup, index);
};

export const getGroupTitleAttrName = (index: number): string => {
    return getDataTestAttrName(pageNames.main, localElementNames.groupTitle, index);
};

export const getGroupListAttrName = (index: number): string => {
    return getDataTestAttrName(pageNames.main, localElementNames.groupList, index);
};
