import {
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react';
import { isClientSide } from '../helpers/ssr';

type MediaType = {
    mediaQueryString: string;
    options: {
        withoutSSR?: boolean;
    };
};

type PropsType = (mediaQueryString: MediaType['mediaQueryString'], options?: MediaType['options']) => boolean[];

export const useMatchMedia: PropsType = (mediaQueryString, options) => {
    const _mql = useMemo<MediaQueryList | null>(() => {
        if (isClientSide()) {
            return window.matchMedia(mediaQueryString);
        } else {
            return null;
        }
    }, [mediaQueryString]);

    const getMatch = useCallback(() => {
        return _mql ? _mql.matches : false;
    }, [_mql]);

    const [isMatch, setIsMatch] = useState(options?.withoutSSR ? getMatch() : false);

    const handleMediaChange = useCallback((e: { matches: boolean | ((prevState: boolean) => boolean) }) => {
        setIsMatch(e.matches);
    }, []);

    useEffect(() => {
        if (!_mql) {
            return;
        }

        if (!options?.withoutSSR) {
            setIsMatch(getMatch());
        }

        if ('function' === typeof _mql.addEventListener) {
            _mql.addEventListener('change', handleMediaChange);
        }

        return () => {
            if ('function' === typeof _mql.removeEventListener) {
                _mql.removeEventListener('change', handleMediaChange);
            }
        };
    }, [_mql, options?.withoutSSR, handleMediaChange, getMatch]);

    return [isMatch];
};
