import React, { FC } from 'react';
import SVG from 'react-inlinesvg';

interface IconProps {
    src: string;
    width?: number;
    height?: number;
    className?: string;
}

export const Icon: FC<IconProps> = ({ src, width, height, className }) => {
    return (
        <div className={className} style={{ width, height, minWidth: width, minHeight: height }}>
            <SVG src={src} width={width || 'auto'} height={height || 'auto'} cacheRequests={true} />
        </div>
    );
};
