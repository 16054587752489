import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { Themeinterface } from '../../../styles/themes/theme.type';
import { borderRadius } from '../../../styles/tokens/border';
import { color } from '../../../styles/tokens/color';
import Variables from '../../../styles/variables';

export const LinkTitle = styled.span<{ theme: Themeinterface }>`
    font-family: ${Variables.fonts.fontRegular};
    color: ${({ theme }) => theme.textPrimary};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const LinkDescription = styled.span<{ theme: Themeinterface }>`
    font-family: ${Variables.fonts.fontRegular};
    color: ${({ theme }) => theme.textSecondary};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${rem(44)};
    min-width: ${rem(44)};
    max-height: ${rem(44)};
    max-width: ${rem(44)};
    height: ${rem(44)};
    width: ${rem(44)};
    margin-right: ${rem(12)};
    overflow: hidden;
    border-radius: ${borderRadius.radius16};

    img {
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
`;

export const GroupIconWrapper = styled(IconWrapper)`
    max-height: ${rem(24)};
    max-width: ${rem(24)};
    min-height: ${rem(24)};
    min-width: ${rem(24)};
    height: ${rem(24)};
    width: ${rem(24)};
    border-radius: ${borderRadius.radius4};
    flex-shrink: 0;
`;

export const Container = styled.div`
    position: relative;
    cursor: pointer;
    overflow: hidden;
`;

export const Item = styled.a<{ isLoading: boolean; theme: Themeinterface }>`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${rem(10)} 0;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    opacity: ${({ isLoading }) => isLoading ? '0.5' : '1'};

    ${({ isLoading }) => isLoading && css`pointer-events: none`};

    &:hover {
        ${LinkTitle} {
            color: ${({ theme }) => theme.textSecondary};
        }

        ${GroupIconWrapper} {
            svg {
                & > path:last-of-type {
                    fill: ${color.controlsLightPrimaryActive};
                }
            }
        }
    }
`;

export const LinkTextBlock = styled.span`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
`;
