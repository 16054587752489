import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';

export const ChildSegmentsBlock = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${rem(272)}, 1fr));
    column-gap: ${rem(32)};
`;
