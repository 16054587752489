import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './segment-products.styles';
import { ABTestContext } from '../../../context/ab-test-context';
import { SegmentApiNameEnum } from '../../../models/enums/segment-api-name.enum';
import { ChildSegment } from '../../../models/segment';
import { selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import ChildSegmentLink from '../child-segment-link/child-segment-link';
import ChildSegmentLinkForAB from '../child-segment-link/child-segment-link-for-ab';

export const SegmentProducts: FC<{ childSegment: ChildSegment }> = ({ childSegment }) => {
    const { mts_bank_group: abTest } = useContext(ABTestContext);
    const segmentCode = useSelector(selectCurrentSegmentCode);

    return (
        <Styled.Wrapper>
            {childSegment.name && <Styled.Title>{childSegment.name}</Styled.Title>}
            {childSegment.code === 'finance' && abTest && segmentCode === SegmentApiNameEnum.individuals && <ChildSegmentLinkForAB/>}
            {childSegment.products?.map(product => <ChildSegmentLink product={product} key={product.id} />)}
        </Styled.Wrapper>
    );
};
