import { useEffect, useState } from 'react';
import { useQuery } from './use-query';
import { UrlTypes, withUtm } from '../helpers/with-utm';

export const useWithUtm = <T extends UrlTypes>(href: T) => {
    const query = useQuery();
    const [newHref, setNewHref] = useState(withUtm(href, query));

    useEffect(() => {
        setNewHref(withUtm(href, query));
    }, [href]);

    return newHref;
};
