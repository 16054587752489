export const localElementNames = {
    productGroup: 'product-group',
    groupTitle: 'group-title',
    groupList: 'group-list',
};

export const hardcodedProducts = [
    {
        code: 'mts_zony_obsluzhivaniya',
        href: 'https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/nasha-set?on=lte',
    },
    {
        code: 'mts_magazin',
        href: 'https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/offices',
    },
];
