//  Добавление неразрывного пробела в конце строки, чтобы союзы и предлоги не переносились на следующую строку
const quantityCharsFromTheEnd = 4;
const minimimStringLengthForAdding = 20;

export const makeLastSpaceNonBreaking = (text: string): string => {
    // Возвращаем изначальный текст, если строка короткая
    if (text.length <= minimimStringLengthForAdding) {
        return text;
    }

    const charList = text.split('');
    const lastSpaceIndex = charList.lastIndexOf(' ');

    const notSearchedArea = charList.length - quantityCharsFromTheEnd;

    // Возвращаем изначальный текст, если пробелов нет
    // или если пробел стоит не в последних 4 символах строки
    if (lastSpaceIndex === -1 || lastSpaceIndex < notSearchedArea) {
        return text;
    }

    //Замена пробела на неразрывный пробел
    charList[lastSpaceIndex] = '\u00A0';

    return charList.join('');
};
